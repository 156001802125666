const overloading = {
  breakpointNames: ['sm', 'md', 'lg', 'xl', 'xxl'],
  breakpoints: [576, 768, 992, 1200, 1600]
}

const colors = {
  dkGreenColor : '#00391f',
  dkGrayColor : '#3b3b3b',
  yellowColor : '#ffdd00'
}

const variables = {
  familyBase: '"proxima-nova",sans-serif',
  familyHeader: '"proxima-nova",serif',

  primaryColor: '#c20f2f',
  fontColor: colors.dkGrayColor,
  bgColor: '#ebebeb',

  ...colors,
  ...overloading
}

export default variables;