/** @jsx jsx */
import { jsx } from "@emotion/core";
import { useState, useLayoutEffect, useEffect, useRef } from "react";
import utils from "utils";
import { createPortal } from "react-dom";
import style from './nowbookWidgetStyle';
import { Fragment } from "react";
import cmsUtils from "../../cmsUtils";

function Portal({ children }) {
    const [el] = useState(document.createElement('div'));
    useEffect(() => {
        let mount = document.getElementById("portal-root");
        mount.appendChild(el);
        return () => mount.removeChild(el);
    }, [el]);
    return createPortal(children, el);
}

export function NowbookWidget(props) {

    const item = props.item;
    const cmsOption = props.cmsOption || {};
    const popupOpenType = cmsUtils.payload(item, 'PopupOpenType') || 'window'; // 'scroll'
    const cssClass = utils.classNames("cms_item", "nowbook__widget", "portal__background", item.cssClass || item.anchorName || "");

    const [openPopup, setOpenPopup] = useState(false);
    const scrollTigger = useRef(null);

    function closePopupFunction(e) {
        setOpenPopup(false);
        document.body.style.overflow = "unset";
    }

    useLayoutEffect(() => {

        const existingScript = document.getElementById('nowbookWidgetFrame');
        if (existingScript) {
            document.body.removeChild(existingScript);
        }

        const script = document.createElement('script');
        script.type = `text/javascript`;
        script.id = 'nowbookWidgetFrame';
        script.src = 'https://plugins.nowbookit.com/iframe-resizer-build/bundle.js';

        if (!cmsOption.isCmsEdit && popupOpenType === 'window') {
            setOpenPopup(true);
            document.body.style.overflow = "hidden";
            document.body.appendChild(script);
        }

        function isInViewport(element) {
            const rect = element.getBoundingClientRect();
            return (
                rect.top >= 0 &&
                rect.left >= 0 &&
                rect.bottom <= (window.innerHeight || document.documentElement.clientHeight) &&
                rect.right <= (window.innerWidth || document.documentElement.clientWidth)
            );
        }

        const onScroll = () => {
            const el = scrollTigger.current;
            let _isInViewport = isInViewport(el);
            if (_isInViewport === true) {
                if (el.dataset.checkPopupOpen === 'false') {
                    setOpenPopup(true);
                    document.body.style.overflow = "hidden";
                    document.body.appendChild(script);
                    el.dataset.checkPopupOpen = 'true';
                    window.removeEventListener('scroll', onScroll);
                }
            }
        };

        if (!cmsOption.isCmsEdit && popupOpenType === 'scroll'){
            window.addEventListener('scroll', onScroll);
        }

        return () => { 
            if (!cmsOption.isCmsEdit && popupOpenType === 'scroll'){
                window.removeEventListener('scroll', onScroll);
            }
        };

        // eslint-disable-next-line
    }, []);

    return (
        <Fragment>
            <Portal>
                {
                    openPopup &&
                    <Fragment>
                        <div className={cssClass} data-cms-item-id={cmsOption.isCmsEdit && item.itemId} id={item.anchorName} css={style.portal__background} onClick={closePopupFunction}></div>
                        <div className="portal__popupcontainer row" css={style.portal__popupcontainer}>
                            <div className="top__img" css={style.top__img}>
                                <img src='/assets/book-online headding.png' alt="Book online" />
                            </div>
                            <div className="button__container" css={style.button__container}>
                                <button className="btn btn-primary close__btn" type="button" css={style.close__btn} onClick={closePopupFunction}> CLOSE </button>
                            </div>
                            <div style={{ position: 'relative', width: '100%', height: 'auto' }}>
                                <iframe title="nbi-widget" data-id="nbi-widget" src='https://bookings.nowbookit.com/?accountid=88422fcd-c8ef-473d-8385-f95bd53e3557&venueid=1677&theme=light&colors=hex,0c5635,ffd600&font=Barlow'></iframe>
                                {/* <button className="btn btn-primary close__btn" type="button" css={style.close__btn} onClick={closePopupFunction}> CLOSE </button> */}
                            </div>
                        </div>
                    </Fragment>
                }
            </Portal>
            <div ref={scrollTigger} css={style.css__trigger} data-check-popup-open='false'></div>
            {
                cmsOption.isCmsEdit &&
                <div className="nowbook__widget__cms__item" css={style.nowbook__widget__cms__item}> Nowbook Widget - When choose scroll, popup will open at this point when scroll </div>
            }
        </Fragment>
    )
}