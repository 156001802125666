import { mq } from '../../../../cssInJs';


export default {
    portal__background: {
        backgroundColor: 'rgba(0,0,0,0.8)',
        position: 'fixed',
        top: '0px',
        left: '0px',
        width: '100%',
        height: '100%',
        zIndex: '9998',
    },

    portal__popupcontainer : mq({
        position: 'fixed',
        top: '50%',
        height: '102%',
        overflow: 'auto',
        left : ['53%',null,'50%'],
        transform: 'translate(-50%, -50%)',
        //maxWidth: '560px',
        maxWidth: ['320px',null,'560px'],
        width: '100%',
        backgroundColor : 'white',
        zIndex: '9999',
        //paddingTop: '2rem'
    }),

    top__img : {
        'img' : {
            width: '100%'
        }
    },

    button__container : {
        width: '100%',
        textAlign: 'right',
        marginTop: '-3px'
    },

    close__btn : mq({
        //top : '0px',
        //right: '10px',
        //position: 'absolute',
        fontSize:'10px',
        fontWeight: 600,
        //textAlign: 'right',
    }),
    css__trigger : {
        width : '1px',
        height: '1px'
    },
    nowbook__widget__cms__item : {
        textAlign : 'center',
        margin: '1rem',
        backgroundColor: 'gray',
        color: 'white',
        paddingTop: '1rem',
        paddingBottom: '1rem',
        fontWeight : '700'
    }
}