import { fb, validators } from '../../../../lib/form';

export function getBookingFormModel(data) {
  

  const hourList = [
    //{name: 'Hour*', value: ''},
    {name: '07 AM', value: '07 AM'},
    {name: '08 AM', value: '08 AM'},
    {name: '09 AM', value: '09 AM'},
    {name: '10 AM', value: '10 AM'},
    {name: '11 AM', value: '11 AM'},
    {name: '12 PM', value: '12 PM'},
    {name: '01 PM', value: '01 PM'},
    {name: '02 PM', value: '02 PM'},
    {name: '03 PM', value: '03 PM'},
    {name: '04 PM', value: '04 PM'},
    {name: '05 PM', value: '05 PM'},
    {name: '06 PM', value: '06 PM'},
    {name: '07 PM', value: '07 PM'},
    {name: '08 PM', value: '08 PM'},
    {name: '09 PM', value: '09 PM'},
  ];

  const minList = [
    //{name: 'Hour*', value: ''},
    {name: '00', value: '00'},
    {name: '15', value: '15'},
    {name: '30', value: '30'},
    {name: '45', value: '45'}
  ];

  const agreeList = [
    {name: 'Yes, I am happy to receive marketing emails', value: 'Yes, I am happy to receive marketing emails'},
    {name: 'No, please do not send me marketing emails', value: 'No, please do not send me marketing emails'},
  ];

  const model = fb.group({ 
    firstName: [data.firstName || '', [validators.Required()], { label: 'First Name', type: 'text' }],
    lastName: [data.lastName || '', [validators.Required()], { label: 'Last Name', type: 'text' }],
    phone: [data.phone || '', [validators.Required()], { label: 'Phone Number', type: 'text' }],
    email: [data.email || '', [validators.Required(), validators.Email()], { label: 'Email', type: 'email' }],
    eventDate: [data.eventDate || '', [validators.Required()], { label: 'Event Date', type: 'date' }],
    startTimeHour: [data.startTimeHour || '07 AM', [validators.Required()], { label: 'Start Time Hour', type: 'select', options: hourList }],
    startTimeMin: [data.startTimeMin || '00', [validators.Required()], { label: 'Start Time Minute', type: 'select', options: minList }],
    endTimeHour: [data.endTimeHour || '07 AM', [validators.Required()], { label: 'End Time Hour', type: 'select', options: hourList }],
    endTimeMin: [data.endTimeMin || '00', [validators.Required()], { label: 'End Time Minute', type: 'select', options: minList }],
    occasion : [data.occasion  || '', [validators.Required()], { label: 'Occasion eg: 30th Birthday', type: 'text' }],
    numberOfGuests : [data.numberOfGuests  || '', [validators.Required()], { label: 'Number Of Guests', type: 'number' }],
    additionalNotes: [data.additionalNotes || '', [], { label: 'Additional Notes', type: 'textarea' }],
    agreeToMarketing: [data.agreeToMarketing || 'Yes, I am happy to receive marketing emails', [validators.Required()], { label: 'Do You Agree to Marketing ?', type: 'select', options: agreeList }],
  }); 
  return model;
}