/** @jsx jsx */
import { jsx } from "@emotion/core";
import React, { useRef } from "react";
import utils from "utils";
import { getBookingFormModel } from "./bookingFormModel";
import { usePost, Button, ErrorMessage } from "../../../../components";
import { useForm } from "../../../../form";
import cmsUtils from "../../cmsUtils";
//import style from './bookingFormStyle';
import env from '../../../../env';
import ReCAPTCHA from "react-google-recaptcha";

export function BookingForm(props) {

    const item = props.item;
    const cmsOption = props.cmsOption || {};
    const cssClass = utils.classNames(
        "cms_item",
        "booking-form",
        item.cssClass || item.anchorName || ""
    );

    const emailReceiver = cmsUtils.payload(item, 'EmailReceiver');
    //const fileAttachment = cmsUtils.payload(item, 'FileAttachment') || "false";
    const mailchimpApiKey = cmsUtils.payload(item, 'MailchimpApiKey');
    const mailchimpAudienceKey = cmsUtils.payload(item, 'MailchimpAudienceKey');

    const model = getBookingFormModel({});

    const form = useForm(model, { usePlaceholder: true });
    const [sendingStatus, setSendingStatus] = React.useState(null);
    const [mailChimpError, setMailChimpError] = React.useState();
    const post = usePost();
    const recaptchaRef = useRef(null);

    const mailChimpErrorMemo = React.useMemo(() => {
        return mailChimpError;
    }, [mailChimpError]);


    function onSubmit(e) {
        setMailChimpError(null);
        form.validateForm(e, () => {
            const recaptchaValue = recaptchaRef.current.getValue();
            const value = {
                ...form.getValue(),
                emailReceiver: emailReceiver,
                mailchimpApiKey: mailchimpApiKey,
                mailchimpAudienceKey: mailchimpAudienceKey,
                recaptchaValue: recaptchaValue
            };
            var files = form.getFiles();
            var formData = new FormData();
            formData.append('jsonData', JSON.stringify(value))
            files.forEach(file => {
                (file.files || []).forEach(uploadFile => {
                    formData.append(file.name, uploadFile)
                })
            });
            //post.send(env.apiBase + "/api/contact/send", value);
            post.sendFormData(env.apiBase + "/api/contact/book", formData);
            setSendingStatus('pending')
        })

        console.log('submitting')
    }

    if (post.done() && sendingStatus === 'pending') {
        //var value = form.getValue();
        //gtm.sendEvent('contactFormSubmitted', { contactEmail: value.email })
        form.reset(model);
        recaptchaRef.current.reset();
        setSendingStatus(null);

        if (post.response.results && (post.response.results.MailChimpError && post.response.results.MailChimpError !== "")) {
            setMailChimpError("Mail Chimp Error : " + post.response.results.MailChimpError);
        }
    }

    const render = (name) => form.renderControl(name, null);

    return (
        <div
            className={cssClass}
            data-cms-item-id={cmsOption.isCmsEdit && item.itemId}
            id={item.anchorName}>
            <div className="container contact-wrapper" id="bookingForm">
                <form className="contactForm">

                    <div className="form-row">
                        <div className="col-md-6 mb-2">{render('firstName')}</div>
                        <div className="col-md-6 mb-2">{render('lastName')}</div>
                    </div>

                    <div className="form-row">
                        <div className="col-md-6 mb-2">{render('phone')}</div>
                        <div className="col-md-6 mb-2">{render('email')}</div>
                    </div>
                    <div className="form-row">
                        <div className="col-md-12 mb-2">{render('eventDate')}</div>
                    </div>
                    <div className="form-row">
                        <div className="col-md-6 mb-2">{render('startTimeHour')}</div>
                        <div className="col-md-6 mb-2">{render('startTimeMin')}</div>
                    </div> 
                    <div className="form-row">
                        <div className="col-md-6 mb-2">{render('endTimeHour')}</div>
                        <div className="col-md-6 mb-2">{render('endTimeMin')}</div>
                    </div>
                    <div className="form-row">
                        <div className="col-md-6 mb-2">{render('occasion')}</div>
                        <div className="col-md-6 mb-2">{render('numberOfGuests')}</div>
                    </div>
                    <div className="form-row">
                        <div className="col-md-12 mb-2">{render('additionalNotes')}</div>
                    </div>
                    <div className="form-row">
                        <div className="col-md-12 mb-2">{render('agreeToMarketing')}</div>
                    </div>
                    {/* {fileAttachment === 'true' &&
                        <div className="form-row">
                            <div className="col-md-12 mb-2"> {render('attachments')} </div>
                        </div>
                    } */}

                    <div className="form-row">
                        <div className="col-md-12 mb-2 recapthca-container">
                            <ReCAPTCHA
                                ref={recaptchaRef}
                                sitekey={env.recaptchaSiteKey}>
                            </ReCAPTCHA>
                        </div>
                    </div>

                    <div className="form-row">
                        <div className="col-md-12 mb-2">
                            <div className="actions">
                                <Button onClick={onSubmit} status={post.status}> Submit </Button>
                                <ErrorMessage errors={form.errors} summaryMessage="Please review the errors." />
                                <ErrorMessage errors={post.errors} />
                                <ErrorMessage errors={mailChimpErrorMemo} />
                                {post.done() && <span style={{ marginLeft: '1.5rem', color: 'red', fontWeight: 'bold' }}>Thank you for submitting an enquiry, one of our team members will be in touch soon.</span>}
                            </div>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    );
}