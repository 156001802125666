/** @jsx jsx */
import { jsx } from "@emotion/core";
import React from "react";
import utils from "utils";
import { getLoginFormModel } from "./loginFormModel";
import { usePost, Button, ErrorMessage } from "../../../../components";
import { useForm } from "../../../../form";
import cmsUtils from "../../cmsUtils";
import { Redirect } from "react-router-dom";
//import style from './bookingFormStyle';
import env from '../../../../env';
//import { Redirect } from "react-router-dom";
//import ReCAPTCHA from "react-google-recaptcha";

export function LoginForm(props) {

    const item = props.item;
    const cmsOption = props.cmsOption || {};
    const cssClass = utils.classNames(
        "cms_item",
        "login-form",
        item.cssClass || item.anchorName || ""
    );

    const emailReceiver = cmsUtils.payload(item, 'EmailReceiver');
    //const fileAttachment = cmsUtils.payload(item, 'FileAttachment') || "false";
    const mailchimpApiKey = cmsUtils.payload(item, 'MailchimpApiKey');
    const mailchimpAudienceKey = cmsUtils.payload(item, 'MailchimpAudienceKey');

    const model = getLoginFormModel({});

    const form = useForm(model, { usePlaceholder: true });
    const [sendingStatus, setSendingStatus] = React.useState(null);
    //const [mailChimpError, setMailChimpError] = React.useState();
    const post = usePost();
    const render = (name) => form.renderControl(name, null);
    //const recaptchaRef = useRef(null);

    // const mailChimpErrorMemo = React.useMemo(() => {
    //     return mailChimpError;
    // }, [mailChimpError]);


    function onSubmit(e) {
        //setMailChimpError(null);
        form.validateForm(e, () => {
            //const recaptchaValue = recaptchaRef.current.getValue();
            const value = {
                ...form.getValue(),
                emailReceiver: emailReceiver,
                mailchimpApiKey: mailchimpApiKey,
                mailchimpAudienceKey: mailchimpAudienceKey,
                //recaptchaValue: recaptchaValue
            };
            var files = form.getFiles();
            var formData = new FormData();
            formData.append('jsonData', JSON.stringify(value))
            files.forEach(file => {
                (file.files || []).forEach(uploadFile => {
                    formData.append(file.name, uploadFile)
                })
            });
            //post.send(env.apiBase + "/api/contact/send", value);
            post.sendFormData(env.apiBase + "/api/contact/login", formData);
            setSendingStatus('pending')
        })
    }

    if (post.done() && sendingStatus === 'pending') {
        //var value = form.getValue();
        //gtm.sendEvent('contactFormSubmitted', { contactEmail: value.email })
        //form.reset(model);
        //recaptchaRef.current.reset();
        //setSendingStatus(null);
        //history.push('/member');
        //return null;
        return <Redirect to="/member/filezone-pid=8563797468" />;
    }

    return (
        <div
            className={cssClass}
            data-cms-item-id={cmsOption.isCmsEdit && item.itemId}
            id={item.anchorName}>
            <div className="container contact-wrapper" id="loginForm">
                <form className="contactForm" autocomplete="off">

                    <div className="form-row">
                        <div className="col-12 mb-2">{render('userName')}</div>
                        <div className="col-12 mb-2">{render('password1')}</div>
                    </div>
 
                    <div className="form-row">
                        <div className="col-md-12 mb-2">
                            <div className="actions">
                                <Button onClick={onSubmit} status={post.status}> Login </Button>
                                <ErrorMessage errors={form.errors} summaryMessage="Please review the errors." />
                                <ErrorMessage errors={post.errors} />
                                {post.done() && <span style={{ marginLeft: '1.5rem', color: 'red', fontWeight: 'bold' }}> Successfully login! </span>}
                            </div>
                        </div>
                    </div>

                </form>
            </div>
        </div>
    );
}